
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "contrats",
  setup() {
    const state = reactive({
      loaderEnabled: true,
        refreshRouter: 0,
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Gestion des Contrats de support & Abonnements", []);
    });

    const refreshRouter = () => {
      state.refreshRouter += 1;
    }

    return {
      state,
      refreshRouter,
    }
    
  },
});
