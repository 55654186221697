<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-xl-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8">
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/contrats/contrats_overview"
            active-class="active"
          >
            {{$t("Vue d'ensemble")}}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/contrats/listEquipement"
            active-class="active"
          >
            {{$t("Vue par équipement")}}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/contrats/list"
            active-class="active"
          >
            {{$t("Vue par contrat")}}
          </router-link>
        </li>
        <!--
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/contrats/ea"
            active-class="active"
          >
            {{$t("Enterprise agreement")}}
          </router-link>
        </li>
        -->
        <li class="nav-item">
          <router-link
            class="nav-link text-active-light me-6"
            to="/contrats/case"
            active-class="active"
          >
            {{$t("FAQ support")}}
          </router-link>
        </li>
      </ul>
      <router-view :key="state.refreshRouter " @refreshRouter="refreshRouter($event)"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "contrats",
  setup() {
    const state = reactive({
      loaderEnabled: true,
        refreshRouter: 0,
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Gestion des Contrats de support & Abonnements", []);
    });

    const refreshRouter = () => {
      state.refreshRouter += 1;
    }

    return {
      state,
      refreshRouter,
    }
    
  },
});
</script>
